.PlayerInfo {
  align-items: center;
  display: flex;
}

.PlayerImage {
  background-color: #eee;
  border-radius: 50%;
  height: 24px;
  margin-right: 6px;
}

.Hidden {
  color: white;
}

tr .AverageTime {
  background-color: #eee;
}

tr .AverageTimeWinner {
  background-color: #ddd;
}

.SortButton {
  background-color: transparent;
  border: none;
  cursor: pointer;
  display: inline;
  font-family: "Roboto";
  font-weight: 500;
  margin: 0;
  padding: 0;
  text-decoration: underline;
}

.SortIcon {
  height: .6em;
  margin-left: .2em;
  vertical-align: middle;
  width: .6em;
}

.Chart {
  height: 300px;
  max-width: 600px;
  padding: 2em;
  width: calc(100% - 4em);
}
