.QualifiersContainer {
  margin-top: 20px;
  max-width: 800px;
}

.Title {
  display: flex;
}

.AddButton {
  margin-left: 20px;
}

.PlayerInfo {
  align-items: center;
  display: flex;
}

.PlayerImage {
  background-color: #eee;
  border-radius: 50%;
  height: 24px;
  margin-right: 6px;
}

@media screen and (max-width: 600px) {
  .Title {
    justify-content: space-between;
  }

  .AddButton {
    border-radius: 50%;
    min-width: initial;
    padding: 5px;
  }

  .AddButton {
    :global {
      .MuiButton-startIcon {
        margin: 0;
      }
    }
  }

  .AddButton .Text {
    display: none;
  }
}

.NoWrap {
  white-space: nowrap;
}

.TrendIconUp {
  font-size: 24px;
  color: green;
  vertical-align: middle;
}

.TrendIconDown {
  font-size: 24px;
  color: red;
  vertical-align: middle;
}

.IsDrop {
  background-color: #fdd;
}
