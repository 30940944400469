$header-height: 64px;
$header-height-mobile: 60px;
$page-margin: 16px;

.Header {
  align-items: center;
  display: flex;
  gap: 16px;
}

.Sheet {
  margin: -$page-margin;
  min-height: calc(100vh - #{$header-height} - calc(2 * #{$page-margin}));
  padding: $page-margin;
}

@media (max-width: 599px) {
  .Sheet {
    min-height: calc(
      100vh - #{$header-height-mobile} - calc(2 * #{$page-margin})
    );
  }
}

.Title {
  margin-bottom: 8px;
}

.TabPanel {
  padding-block-end: 0;
  padding-inline-start: 0;
  padding-inline-end: 0;
}

.TabPanelQualifiers {
  padding-block-start: 0;
}
