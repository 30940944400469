.AdminEditContainer {
  margin-bottom: 100px;
}

.AdminEditContainer .TableRow:hover {
  background-color: #eee;
}

.AdminEditContainer .ActionsCell {
  padding-bottom: 0;
  padding-top: 0;
}

.AdminEditContainer .Fab {
  position: fixed;
  right: 20px;
  bottom: 20px;
}

.AdminEditProgress.isHidden {
  visibility: hidden;
}

.AdminEditForm {
  min-width: 380px;
}

.AdminEditForm .Input {
  width: 100%;
}

.AdminEditForm .Input:first-child {
  margin-top: 20px;
}

.AdminEditForm .Input:not(:last-child) {
  margin-bottom: 20px;
}
