.Container {
  max-width: 800px;
}

.Sheet {
  border-radius: 8px;
  overflow: auto;
  padding: 0 8px 8px;
}

.Table {
  table-layout: auto;
  --TableCell-paddingY: 2px;
  --Avatar-size: 28px;
}

.Table .CellRight {
  text-align: right;
}

.Table .CellCenter {
  text-align: center;
}

.Table th.CellColspan {
  border-bottom-width: var(--Table-headerUnderlineThickness);
}

.PlayerInfo,
.Scores {
  align-items: center;
  display: flex;
}

.PlayerInfo {
  gap: 8px;
}

.Scores {
  justify-content: space-between;
}

.Table .IsDrop,
.Table .IsDrop:hover {
  background-color: #fdd;
}

.IsDrop td:first-child {
  font-weight: 700;
}

.NoWrap {
  white-space: nowrap;
}

@media screen and (max-width: 600px) {
  .Container {
    width: 100%;
  }
}
