$bracket-gap: 4px;
$match-border-radius: 4px;
$match-padding: 6px;

.Bracket {
  display: flex;
  flex-direction: row;
  gap: $bracket-gap;
  overflow-x: auto;
}

.RoundContainer {
  display: flex;
  flex-direction: column;
  gap: $bracket-gap;
  max-width: 250px;
  min-width: 250px;
}

.RoundHeading {
  --Card-padding: 8px;
  text-align: center;
}

.Bracket .Round * {
  --Card-padding: 0;
  --joy-lineHeight-md: 1;
}

.Round {
  display: flex;
  flex-direction: column;
  gap: $bracket-gap;
}

.Match {
  border-radius: $match-border-radius;
  border-style: solid;
  border-width: 1px;
  padding: 2px;
}

.MatchPlayer {
  align-items: center;
  display: grid;
  gap: $match-padding;
  grid-template-columns: 24px 1fr min-content;
}

.MatchPlayerTop {
  border-top-left-radius: $match-border-radius;
  border-top-right-radius: $match-border-radius;
}

.MatchPlayerBottom {
  border-bottom-left-radius: $match-border-radius;
  border-bottom-right-radius: $match-border-radius;
}

.Seed {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: flex-end;
  padding-right: 4px;
  text-align: right;
}

.MatchPlayerTop .Seed {
  border-top-left-radius: $match-border-radius;
}

.MatchPlayerBottom .Seed {
  border-bottom-left-radius: $match-border-radius;
}

.GamerTag {
  flex-grow: 1;
  padding: $match-padding 0;
}

.Games {
  align-items: center;
  display: flex;
  flex-direction: row;
  padding-right: $match-padding;
}
